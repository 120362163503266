import { Button } from "@/components/ui/button"
import {
    Copy,
    PanelLeft,
  } from "lucide-react"
  import firebase, { database, signOut } from "../firebase";

  import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "@/components/ui/alert-dialog";

type LogoutboxProps = {
    user: firebase.User,
    onLogout: () => void;
}

export const LogoutBox = ({ user, onLogout } : LogoutboxProps) => {
  if (user && user.isAnonymous) {
    return (     
      
      <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>Logout Anonymous Mode</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. You will lose all the data that you have entered (if any).
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onLogout}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
    );
  }

  return (
    <Button onClick={onLogout}>Logout</Button>
  )
}