import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { signInWithGoogle, signInAnonymously, createUser, signInWithEmailAndPassword } from "../../firebase";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { AlertCircle } from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

export const LoginOptionsSection = () => {
  const [loginError, setLoginError] = useState('');
  const [isSignUpFormOpen, setIsSignUpFormOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

 const onSignInWithEmailAndPassword = () => {
  setLoginError('');
  if (!email || !password) {
    setLoginError('Email or Password is empty.');
  } else {
    signInWithEmailAndPassword(email, password).then(() => {
      // ...
    })
    .catch((error) => {
      setLoginError('Invalid Email or Password.');
    });
  }
}

  return (
    <>
  <Card
  className="xl:col-span-1" x-chunk="dashboard-05-chunk-0"
>
  <CardHeader className="pb-3">
    <CardTitle>Login to unlock RTO Tracker</CardTitle>
  </CardHeader>
  <CardContent>

<div>
<div className="grid gap-4">
    <div className="grid gap-2">
      <Label htmlFor="email">Email</Label>
      <Input
        id="email"
        type="email"
        required
        value={email}
        onChange={(e) => {setEmail(e.currentTarget.value)}}
      />
    </div>
    <div className="grid gap-2">
      <div className="flex items-center">
        <Label htmlFor="password">Password</Label>
        {/* <a
          href="#"
          className="ml-auto inline-block text-sm underline"
        >
          Forgot your password?
        </a> */}
      </div>
      <Input 
        id="password" 
        type="password" 
        required
        value={password}
        onChange={(e) => {setPassword(e.currentTarget.value)}}
      />
    </div>

    <Button type="submit" className="w-full" onClick={onSignInWithEmailAndPassword}>
      Login
    </Button>

    {loginError ? (
    <Alert variant="destructive">
    <AlertCircle className="h-4 w-4" />
    <AlertTitle>Error</AlertTitle>
    <AlertDescription>
      {loginError}
    </AlertDescription>
  </Alert>

    ): null}

    <div className="flex items-center">
      or using the following options:
      </div>


      <Button variant="outline" className="w-full" onClick={signInWithGoogle}>
        Personal Google Account
      </Button>

      <AlertDialog>
      <AlertDialogTrigger asChild>
      <Button variant="outline" className="w-full">
            Use Anonymous Mode
          </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Anonymous Mode</AlertDialogTitle>
          <AlertDialogDescription>
          <p>Anonymous Mode is good for trying out this RTO tracker tool without providing your email.</p>
          <p>However, your data would be lost after broswer closes.</p>
          <p>If you would like to persit your data and see them in any devices and browsers, please use Google Login or Email/Password login.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={signInAnonymously}>Continue with Anonymous Mode</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>

  </div>
  <div className="mt-4 text-center text-sm">
    Don&apos;t have an account?{" "}
    <Button variant="link" onClick={() => {setIsSignUpFormOpen(true)}}>
      Sign up
    </Button>
  </div>
  </div>


  </CardContent>
</Card>

<SignupForm open={isSignUpFormOpen} onOpenChange={setIsSignUpFormOpen}/>
</>
)
}


const SignupForm = ({open, onOpenChange}: {open: boolean, onOpenChange: (open: boolean) => void}) => {
  const [signUpError, setSignUpError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const onSubmit = () => {
    setSignUpError('');

    if (!email || !password || !password2) {
      setSignUpError('Email or Password is empty.');
    } else if (password.length < 6) {
      setSignUpError('Password need to have at least 6 chars long.');
    }
    else if (password != password2) {
      setSignUpError('Password and confirm password don\'t match.');
    } else {
      createUser(email, password).then(() => {
        onOpenChange(false);
      })
      .catch((error) => {

        setSignUpError(error?.message?.replace('Firebase: ', ''));
      });
    }
  }
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Sign up</DialogTitle>
        <DialogDescription>
          Enter your email and password below
        </DialogDescription>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="email" className="text-right">
            Email
          </Label>
          <Input
            id="email"
            className="col-span-3"
            type="email"
            value={email}
            onChange={(e) => {setEmail(e.currentTarget.value)}}
          />
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="password" className="text-right">
            Password
          </Label>
          <Input
            id="password"
            className="col-span-3"
            type="password"
            value={password}
            onChange={(e) => {setPassword(e.currentTarget.value)}}
          />
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="password2" className="text-right">
            Password Again
          </Label>
          <Input
            id="password2"
            className="col-span-3"
            type="password"
            value={password2}
            onChange={(e) => {setPassword2(e.currentTarget.value)}}
          />
        </div>
        {signUpError ? (
    <Alert variant="destructive">
    <AlertCircle className="h-4 w-4" />
    <AlertTitle>Error</AlertTitle>
    <AlertDescription>
      {signUpError}
    </AlertDescription>
  </Alert>

    ): null}
      </div>
      <DialogFooter>
        <Button type="submit" onClick={onSubmit}>Submit</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
  );
}