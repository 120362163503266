import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { ContentType } from "@/enums";
import { getStatsForMonth } from "@/functions";
import { AggregatedQuarterStat, Map, MonthlyStatWithMonth } from "@/types";
import { getQuarterMonths, getNumberOfWorkDays, getNumberOfInOfficeDays } from "../functions";
import { Separator } from "@/components/ui/separator"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import {
  Table,
  Route,
  CalendarCheck,
} from "lucide-react"
import { StatsTable } from "./StatsTable";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useState } from "react";

const getCurrentQuarterStat = (dates: Map<ContentType>) => {
  const currentQuarterMonths = getQuarterMonths();
  const transformedCurrentQuarterMonths = currentQuarterMonths.map((month, index )=> {
    return {month: currentQuarterMonths[index], ...getStatsForMonth(month, dates)}
  });

  return transformedCurrentQuarterMonths;
}

const getLastQuarterStat = (dates: Map<ContentType>) => {
  const firstMonthInQuarter = getQuarterMonths()[0];
  const firstMonthInQuarterDate = new Date(firstMonthInQuarter.replace('-', '/')+'/1');
  const month = (firstMonthInQuarterDate.getMonth());
  const year = firstMonthInQuarterDate.getFullYear();
  const lastQuarterMonths = getQuarterMonths(new Date(`${year}/${month}/1`));
  const transformedLastQuarterMonths = lastQuarterMonths.map((month, index )=> {
    return {month: lastQuarterMonths[index], ...getStatsForMonth(month, dates)}
  });

  return transformedLastQuarterMonths;
}

const getFormattedStatData = (data: MonthlyStatWithMonth[]) => {
  const stat:AggregatedQuarterStat = {
    totalWorkdays: 0,
    totalInOfficeDays: 0,
    percentage: '0',
    daysNeeded: 0,
    startMonth: data?.[0]?.month,
    endMonth: data?.[2]?.month,
  };
  data.forEach(monthStat => {
    stat.totalWorkdays = stat.totalWorkdays + monthStat.numberOfWorkDays;
    stat.totalInOfficeDays = stat.totalInOfficeDays + monthStat.numberOfInOfficeDays;
  });

  stat.percentage = (
    (stat.totalInOfficeDays / stat.totalWorkdays) *
    100
  ).toFixed(2);

  const daysRemaining = Math.ceil(stat.totalWorkdays / 2) - stat.totalInOfficeDays;

  stat.daysNeeded =
  (daysRemaining < 0) ? 0 : daysRemaining;

  return stat;
}

const getCurrentQuarterStatBlock = (
  dates: Map<ContentType>,
  isLoading: boolean,
  aggregatedData: AggregatedQuarterStat,
  currentQuarterStat: {
    numberOfWorkDays: number;
    numberOfInOfficeDays: number;
    percentageInOffice: string;
    month: string;
}[]
) => {
const startMonthDate = new Date(aggregatedData.startMonth.replace('-', '/') + '/1');
const workDays = getNumberOfWorkDays(startMonthDate, new Date(), dates);
const officeDays = getNumberOfInOfficeDays(startMonthDate, new Date(), dates);

const isOnTrack = Math.floor(officeDays - workDays / 2) >= 0;
const onTrackLabel = isOnTrack ? 'On Track' : 'Off Track';

  return (   
     <Card x-chunk="dashboard-01-chunk-0" className="xs:col-span-2 sm:col-span-1">
  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
    <CardTitle className="text-sm font-medium">
      RTO Stats for this quarter ({aggregatedData.startMonth} to {aggregatedData.endMonth})
    </CardTitle>
  </CardHeader>
  <CardContent>
    {!isLoading? (<div className="text-l sm:text-xl font-bold flex items-center space-x-4">
    <Popover>
  <PopoverTrigger>{aggregatedData.percentage}% <Table className="h-3.5 w-3.5 inline" />
                   </PopoverTrigger>
      <PopoverContent className="w-80 text-sm font-normal">
        <div className="flex justify-between space-x-4">
          <div className="space-y-1">
            <StatsTable stats={currentQuarterStat} average={aggregatedData.percentage}/>
          </div>
        </div>
      </PopoverContent>
        </Popover>
      <Separator orientation="vertical" />
      <Popover>
  <PopoverTrigger>{onTrackLabel} <Route className="h-3.5 w-3.5 inline" />
      </PopoverTrigger>
      <PopoverContent className="w-80 text-sm font-normal">
          As for today, {workDays} work days has passed, and you have been in office for {officeDays} days, so you are {onTrackLabel}
        </PopoverContent>
        </Popover>
      
      <Separator orientation="vertical" />
      <Popover>
      <PopoverTrigger>
        {aggregatedData.daysNeeded} Days <CalendarCheck className="h-3.5 w-3.5 inline" />
      </PopoverTrigger>
      <PopoverContent className="w-80 text-sm font-normal">
          As for today, you still need to come into office for {aggregatedData.daysNeeded} more days to reach 50% RTO.
        </PopoverContent>
        </Popover>
        </div>) : <Skeleton className="w-[200px] h-[30px] rounded-full" /> }
  </CardContent>
</Card>
)
};

const getLastQuarterStatBlock = (dates: Map<ContentType>, isLoading: boolean) => {
  const lastQuarterStat = getLastQuarterStat(dates);
  const aggregatedData = getFormattedStatData(lastQuarterStat);

  return (
    <Card x-chunk="dashboard-01-chunk-2" className="xs:col-span-2 sm:col-span-1">
  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
    <CardTitle className="text-sm font-medium">
      RTO % for last quarter ({aggregatedData.startMonth} to {aggregatedData.endMonth})
    </CardTitle>
    {/* <DollarSign className="h-4 w-4 text-muted-foreground" /> */}
  </CardHeader>
  <CardContent>
  {!isLoading ? (<Popover>
  <PopoverTrigger><div className="text-l sm:text-xl font-bold">{aggregatedData.percentage} % <Table className="h-3.5 w-3.5 inline" /></div>
                   </PopoverTrigger>
      <PopoverContent className="w-80 text-sm font-normal">
        <div className="flex justify-between space-x-4">
          <div className="space-y-1">
            <StatsTable stats={lastQuarterStat} average={ aggregatedData.percentage }/>
          </div>
        </div>
      </PopoverContent>
        </Popover>) : <Skeleton className="w-[200px] h-[30px] rounded-full" /> }
  </CardContent>
</Card>
)
};


export const StatsSection = ({dates, isLoading}: {dates: Map<ContentType>, isLoading: boolean}) => {
  const currentQuarterStat = getCurrentQuarterStat(dates);
  const aggregatedData = getFormattedStatData(currentQuarterStat);

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8">
      {getCurrentQuarterStatBlock(dates, isLoading, aggregatedData, currentQuarterStat)}
      {getLastQuarterStatBlock(dates, isLoading)}
      { aggregatedData.daysNeeded === 0 ? (
      <div className="md:col-span-2 p-4 border-black border-y border-solid text-black text-center">Good Job! Your RTO percentage reached 50%!</div>
    ): null}
  </div>
  );
}