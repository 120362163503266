import { format, lastDayOfMonth } from "date-fns";
import { getNumberOfWorkDays, getQuarterMonths } from "./app/functions";
import { ContentType } from "./enums";
import { MonthlyStat, Map } from "./types";



// const WorkDayMap: Map<number> = {
//   "2023-5": 23,
//   "2023-6": 22,
//   "2023-7": 21,
//   "2023-8": 23,
//   "2023-9": 21,
//   "2023-10": 22,
//   "2023-11": 22,
//   "2023-12": 21,
//   "2024-1": 23,
//   "2024-2": 21,
//   "2024-3": 21,
//   "2024-4": 22,
//   "2024-5": 23,
//   "2024-6": 20,
//   "2024-7": 23,
//   "2024-8": 22,
//   "2024-9": 21,
//   "2024-10": 23,
//   "2024-11": 21,
//   "2024-12": 22,
//   "2025-1": 23,
// };


//@deprecated
// export const getNumberOfWorkDays = (monthString: string, dates: Map<ContentType>) => {
//   let datesKey = Object.keys(dates);
//   let matchDatesCount = WorkDayMap[monthString];
//   datesKey.forEach((date) => {
//     if (date.includes(monthString) && dates[date] === ContentType.Exclude) {
//       matchDatesCount--;
//     }
//   });
//   return matchDatesCount;
// };

export const getNumberOfInOfficeDays = (monthString: string, dates: Map<ContentType>) => {
  let datesKey = Object.keys(dates);
  let matchDatesCount = 0;
  datesKey.forEach((date) => {
    if (date.includes(monthString) && dates[date] === ContentType.InOffice) {
      matchDatesCount++;
    }
  });
  return matchDatesCount;
};

export const getStatsForMonth = (month: string, dates: Map<ContentType>): MonthlyStat => {
  const firstDateOfMonth = month+'-1';
  const startMonthDate = new Date(month.replace('-', '/') + '/1');
  const lastDateOfMonth = lastDayOfMonth(startMonthDate);
  const numberOfWorkDays = getNumberOfWorkDays(startMonthDate, lastDateOfMonth,  dates);
  const numberOfInOfficeDays = getNumberOfInOfficeDays(month, dates);

  return {
    numberOfWorkDays,
    numberOfInOfficeDays,
    percentageInOffice: (
      (numberOfInOfficeDays / numberOfWorkDays) *
      100
    ).toFixed(2),
  };
};

export const getCalendarEnabledFirstAndLastDates = () => {
  const firstMonthInQuarter = getQuarterMonths()[0];
  const lastMonthInQuarter = getQuarterMonths()[2]+'/1';
  const firstMonthInQuarterDate = new Date(firstMonthInQuarter+'/1');
  const month = (firstMonthInQuarterDate.getMonth());
  const year = firstMonthInQuarterDate.getFullYear();
  const firstMonthInLastQuarter = format(getQuarterMonths(new Date(`${year}/${month}/1`))[0]+'/1', 'yyyy/M/d');
  const lastDayOfLastMonthInQuarter = format(lastDayOfMonth(lastMonthInQuarter), 'yyyy/M/d');
  return [firstMonthInLastQuarter, lastDayOfLastMonthInQuarter];
}
