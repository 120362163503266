import "../index.css";
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Copy,
  PanelLeft,
} from "lucide-react"
import { LoginBox } from "./LoginBox";
import { useCallback, useEffect, useState } from "react";
import { collection, onSnapshot, doc, setDoc } from "firebase/firestore";
import firebase, { database, signOut } from "../firebase";
import { LogoutBox } from "./LogoutBox";
import { MonthlyStatChart } from "./charts/MonthlyStatChart";
import { LoginOptionsSection } from "./LoginOptionsSection/LoginOptionsSection";
import { StatsSection } from "./StatsSection/StatsSection";
import {CalendarApp} from "../CalendarNew"
import {
  CalendarCheck,
} from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { getCalendarEnabledFirstAndLastDates } from "@/functions";
import { isWithinInterval } from "date-fns";
import { getStatHolidaysByLocation } from "./functions";
import { toast } from "sonner";
import { Toaster } from "@/components/ui/Toaster";

export default function App() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [dates, setDates] = useState({});
  const [userEnteredDates, setUserEnteredDates] = useState({});
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [location, setLocation] = useState<string>('');

  const [firstDate, lastDate] = getCalendarEnabledFirstAndLastDates();

  const uid = user?.uid;
  const isLoading = isAuthLoading || isDataLoading;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setIsAuthLoading(false);
      setUser(user);
    });
  }, []);

  const fetchPost = useCallback(async (uid: string) => {
    const docRef = collection(database, uid);
    const unsub = onSnapshot(docRef, (doc) => {
      let newObj = {};
      doc.forEach((doc) => {
        const newData = doc.data();
        const transformedObject = {};
        for (const [key, value] of Object.entries(newData)) {
          const newKey =  key.replace(/-/g, '/');
          if(isWithinInterval(newKey, { start: firstDate, end: lastDate })) {
          
          (transformedObject as any)[newKey] = value;
          }
        }
        newObj = { ...transformedObject, ...newObj };
      });
    
      setUserEnteredDates(newObj);
      setIsDataLoading(false);
    });

    return unsub;
  }, []);

  useEffect(() => {
    setDates({
      ...userEnteredDates,
      ...getStatHolidaysByLocation(location)
    });
  }, [userEnteredDates, location]);

  const fetchLocationSetting = useCallback(async (uid: string) => {
    const docRef = doc(database, 'preferences/'+uid);
    const unsub = onSnapshot(docRef, (document) => {
      setLocation(document.data()?.location);
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (uid) {
      fetchLocationSetting(uid);
      fetchPost(uid);
    }
  }, [uid, fetchPost]);

  const handleLogout = () => {
    signOut();
    setDates({});
  };

  const onSelectChange = async (value: string) => {
    try{
    await setDoc(doc(database, "preferences", uid!), {
      'location': value,
    });

    setLocation(value);
    toast("Location setting has been saved!")
  }catch(e) {
    console.log(e);
  }
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
    <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
     <CalendarCheck className="h-4.5 w-4.5 inline" /> <span className="font-bold text-lg">RTO Tracker</span>
      </div>
      {user && <LogoutBox user={user} onLogout={handleLogout}/>}
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        {isAuthLoading ? <Skeleton className="w-[100px] h-[20px] rounded-full" /> :
          !user ? 
          (<div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2">
<LoginOptionsSection /> 
          <Card
              className="xl:col-span-1" x-chunk="dashboard-01-chunk-4"
            >
              <CardContent>
              <CalendarApp dates={dates} user={user} />
                </CardContent>
                </Card></div>): (
                <div>
                   <div className="flex gap-2 p-2 justify-end items-center">
                <div><span className="text-sm">Preset Holidays: </span></div>
              <Select value={location} onValueChange={onSelectChange}>
  <SelectTrigger className="w-[180px]">
    <SelectValue placeholder="Select your location" />
  </SelectTrigger>
  <SelectContent>
    <SelectItem value="BC-CA">BC, CA</SelectItem>
    {/* <SelectItem value="CALIFORNIA-US" disabled>California, US</SelectItem> */}
    <SelectItem value="OTHER">Other (no preset)</SelectItem>
  </SelectContent>
</Select>
<Toaster />
              </div>
                <StatsSection dates={dates} isLoading={isLoading} />
                </div>
                )}
      {user ? <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2">

        <Card
            className="xl:col-span-1" x-chunk="dashboard-01-chunk-4"
          >
            <CardContent>
            <CalendarApp dates={dates} user={user} />
              </CardContent>
              </Card>

              <Card className="xl:col-span-1 overflow-hidden" x-chunk="dashboard-01-chunk-5">
            <CardHeader>
              <CardTitle>Stats for last 4 months</CardTitle>
            </CardHeader>
            <CardContent>
              <MonthlyStatChart dates={dates}/>
              </CardContent>
              </Card>
        </div> : null}
      </main>
      </div>
  );
}
