import { useState } from "react";
import Calendar from "react-calendar";
import { CalendarTileContent } from "./CalendarTileContent";
import "react-calendar/dist/Calendar.css";
import { CalendarContainer } from "./styledNew";
import { CustomizedDialog } from "./CustomizedDialogNew";
import { ContentType } from "./enums";
import { Map } from "./types";
import firebase from "./firebase";
import { getQuarterMonths } from "./app/functions";
import { format, lastDayOfMonth } from "date-fns";
import { getCalendarEnabledFirstAndLastDates } from "./functions";

export const CalendarApp = ({
  dates,
  user,
}: {
  dates: Map<ContentType>;
  user: firebase.User | null;
}) => {
  const userDefinedDates = dates;
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if(user) {setOpen(true)};
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getFormattedTileDate = (date: Date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  const renderTileContent = ({ date, view } : {date: Date; view: string}) => {
    const formattedTileDate = getFormattedTileDate(date);

    if (view !== "month") {
      return null;
    }

    return (
      <CalendarTileContent
        type={userDefinedDates[formattedTileDate]}
        tileDate={formattedTileDate}
        isUserDefinedDate={userDefinedDates.hasOwnProperty(formattedTileDate)}
      />
    );
  };

  const onDayCellClicked = (date: Date) => {
    const selectDateType = userDefinedDates[getFormattedTileDate(date)];
    if (selectDateType === ContentType.Holiday) {
      return;
    }
    
    setDate(date);
    handleClickOpen();
  };

  const [firstDate, lastDate] = getCalendarEnabledFirstAndLastDates();
  return (
    <div className="app">
      <CalendarContainer>
        <Calendar
          minDate={new Date(firstDate)}
          maxDate={new Date(lastDate)}
          className={["calendarApp"]}
          onChange={onDayCellClicked}
          value={date}
          tileContent={renderTileContent}
        />
      </CalendarContainer>
      {/* <div className="text-center">Selected date: {date.toDateString()}</div> */}
      <CustomizedDialog
        key={date.toString()}
        isOpen={open}
        handleClose={handleClose}
        selectedDate={date}
        type={userDefinedDates[getFormattedTileDate(date)]}
        user={user}
      />
    </div>
  );
};
