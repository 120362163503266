import { CalendarTileContentProps } from "./types";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import BusinessIcon from "@mui/icons-material/Business";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { ContentType } from "./enums";

export const CalendarTileContent = ({
  type,
  tileDate,
  isUserDefinedDate
}: CalendarTileContentProps) => {

  const day = tileDate.split("/")[2];

  return (
    <div className={`day ${type}`} aria-label={tileDate}>
      {day}
    </div>
  );
};
